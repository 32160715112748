.App {
  text-align: center;
  /* background-color: whitesmoke; */
}

@media (prefers-reduced-motion: no-preference) {
  .Logo {
    animation: App-logo-spin infinite alternate 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: '100%';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}
.Header {
  min-width: 100%;
  text-align: center;
  background-color: dimgray;
  color: white;
  background-color: black;
  font-size: calc(10px + 4vmin);
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AppContent {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Content {
  padding-top: 40px;
  /* min-width: 100%; */
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #ebf1f5;
  /* background-color: red; */
  /* padding: 15px 10px; */

  /* z-index: 10; */
  overflow: hidden;
}
.ButtonContainer {
  min-width: 100%;
}
.Logo {
  margin-bottom: 40px;
  display: flex;
  max-width: 70%;
}

.Button {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 100px;
  border: 1px solid transparent;
  white-space: nowrap;
  min-width: '100%';
  text-align: center;
  justify-content: center;
  font-size: large;
  font-weight: bold;
  text-decoration: none;
}

.KiButton {
  background-color: #0000ff;
  color: white;
  width: 100%;
  margin-bottom: 10px;
}

.HuaButton {
  background-color: #f0a741;
  color: white;
  width: 100%;
  margin-bottom: 10px;
}

.Twitter {
  background-color: #00acee;
  color: white;
}

.Contact {
  background-color: white;
  color: black;
}

.HostingContainer {
  /* height: 100%; */
  width: 100%;
  /* padding: 15px; */
  /* clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  padding-top: 20%; */
  /* position: relative; */

  /* overflow: hidden; */
  margin-top: 40px;
  margin-bottom: 40px;
}

.HostingDivider {
  background-color: #383636;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  height: 100%;
  width: 100%;
  transform: skewY(-3deg);
  padding-top: 25pt;
  padding-bottom: 25pt;
  /* padding-left: 25pt; */
}

.Footer {
  min-width: 100%;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  background-color: black;
}

.Carousel {
  z-index: 2;
  /* margin-left: 25pt; */
}
.SwiperCarousel {
  /* padding-right: 15px; */
}

.CardContainer {
  padding: 15pt;
  flex-direction: column;
  background-color: white;
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
  border-radius: 8px;
}

.CardTitle {
  color: black;
  font-size: large;
  margin-bottom: 10px;
}

.CardContent {
  color: black;
  font-size: medium;
}
.CardImage {
  align-content: center;
  width: 20%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-2deg);
  }
  to {
    transform: rotate(2deg);
  }
}
